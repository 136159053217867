import React from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"

import x from "../../svg/x-black.svg"

import "./mobile-product-header.css"

const MobileProductHeader = ({ productName }) => {
  return (
    <motion.div id={"product-template-mobile-header"}>
      <motion.h1
        className={"uppercase"}
        id={"product-template-product-title-mobile"}
      >
        {productName}
      </motion.h1>
      <Link to={"/"}>
        <motion.img
          src={x}
          alt={"Close Icon"}
          id={"mobile-product-close-icon"}
        ></motion.img>
      </Link>
    </motion.div>
  )
}

export default MobileProductHeader
