import React from "react"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import DesktopHeader from "../components/DesktopHeader/DesktopHeader"
import MobileProductHeader from "../components/MobileProductHeader/MobileProductHeader"
import ProductDetails from "../components/ProductDetails/ProductDetails"
import DesktopFooter from "../components/DesktopFooter/DesktopFooter"
import MobileFooter from "../components/MobileFooter/MobileFooter"

import { useWindowSize } from "../utils/useWindowSize"

import ImmaterialLinesThinSVG from "../svg/immaterial-lines-thin.svg"

export default ({ pageContext, data }) => {
  // Keep track of window width
  const { width } = useWindowSize()

  const zoomWrapperStyle = {
    width: "100%",
    height: "100%",
  }

  const hasAlternativeImages =
    data.image2.childImageSharp !== null && data.image3.childImageSharp !== null

  const Image1 = () => (
    <Img
      fluid={data.image1.childImageSharp.fluid}
      style={{ ...zoomWrapperStyle }}
      imgStyle={{
        objectFit: "contain",
        padding: 10,
      }}
    />
  )

  const Image2 = () => (
    <Img
      fluid={data.image2.childImageSharp.fluid}
      style={{
        margin: "auto auto",
        ...zoomWrapperStyle,
      }}
      imgStyle={{
        objectFit: "contain",
      }}
    />
  )

  const Image3 = () => (
    <Img
      fluid={data.image3.childImageSharp.fluid}
      style={{
        margin: "auto auto",
        ...zoomWrapperStyle,
      }}
      imgStyle={{
        objectFit: "contain",
      }}
    />
  )

  const [images, setImages] = React.useState([
    <Image1 />,
    <Image2 />,
    <Image3 />,
  ])

  return (
    <Layout>
      <SEO title={pageContext.productName} />
      {width > 1278 ? (
        <DesktopHeader />
      ) : (
        <MobileProductHeader productName={pageContext.productName} />
      )}
      <motion.div id={"product-template-container"}>
        <motion.div id={"product-image-primary"}>
          <img
            src={ImmaterialLinesThinSVG}
            alt={"Product Background Lines"}
            id={"product-image-primary-lines"}
          />
          <Zoom
            wrapStyle={{
              position: "absolute",
              top: 0,
            }}
          >
            {images[0]}
          </Zoom>
        </motion.div>
        {hasAlternativeImages ? (
          <>
            <motion.div
              id={"product-image-secondary"}
              onClick={() => {
                setImages([images[1], images[0], images[2]])
              }}
            >
              {images[1]}
            </motion.div>
            <motion.div
              id={"product-image-tertiary"}
              onClick={() => {
                setImages([images[2], images[1], images[0]])
              }}
            >
              {images[2]}
            </motion.div>
          </>
        ) : null}
        <motion.div
          id={"product-details"}
          className={!hasAlternativeImages ? "no-alternate-images" : ""}
        >
          <ProductDetails productDetails={pageContext} productImages={data} />
        </motion.div>
      </motion.div>
      {width > 1278 ? (
        <DesktopFooter />
      ) : (
        <MobileFooter productDetails={pageContext} productImages={data} />
      )}
    </Layout>
  )
}

export const data = graphql`
  query ProductImages($image1: String!, $image2: String!, $image3: String!) {
    image1: file(relativePath: { eq: $image1 }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image2: file(relativePath: { eq: $image2 }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image3: file(relativePath: { eq: $image3 }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
