import React, { useState, useContext } from "react"
import { motion } from "framer-motion"

import Button from "../Button/Button"

import { useWindowSize } from "../../utils/useWindowSize"
import { getSalePrice } from "../../utils/getSalePrice"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import "./product-details.css"

const ProductDetails = ({ productDetails, productImages }) => {
  // Global state and actions
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  // Keep track of window width
  const { width } = useWindowSize()

  // Keep track if item is on sale
  const isOnSale = productDetails.productSale > 0
  const salePrice = getSalePrice(
    productDetails.productPrice,
    productDetails.productSale
  )

  // Hold state for adding to cart
  const [itemAlreadyAdded, setItemAlreadyAdded] = useState(false)

  // Handle adding item to cart if a product is also attached
  const handleAddToCart = () => {
    // Check if item is already in cart
    if (
      state.cart.some(x => {
        return x.product.productName === productDetails.productName
      })
    ) {
      //  Don't add item if already in cart
      setItemAlreadyAdded(true)
      setTimeout(() => {
        setItemAlreadyAdded(false)
      }, 2500)
    }

    // Add item if not already in cart
    dispatch({
      type: "ADD_ITEM",
      payload: {
        product: {
          ...productDetails,
          productImages: { ...productImages },
        },
      },
    })
  }

  return (
    <>
      <motion.h1 className={"header uppercase"} id={"product-title-desktop"}>
        {productDetails.productName}
      </motion.h1>
      <motion.div
        id={"price-size-container"}
        className={isOnSale ? "price-size-container-sale" : undefined}
      >
        <motion.h3
          className={"paragraph uppercase"}
          id={"product-template-price"}
        >
          PRICE{" "}
          <span className={isOnSale ? "sale" : undefined}>
            €{productDetails.productPrice.toString()}
          </span>
        </motion.h3>

        {isOnSale ? (
          <motion.h3
            className={"paragraph uppercase"}
            id={"product-template-sale"}
          >
            SALE €{salePrice.toString()}
          </motion.h3>
        ) : null}

        <motion.h3
          className={`paragraph uppercase ${
            isOnSale ? "product-template-size-sale" : undefined
          }`}
          id={"product-template-size"}
        >
          SIZE {productDetails.productSize.toUpperCase()}
        </motion.h3>
      </motion.div>
      <motion.h3 className={"paragraph uppercase"}>
        TAXES AND DUTIES INCLUDED.
      </motion.h3>
      <motion.h3 className={"paragraph uppercase"}>
        FREE SHIPPING ON ORDERS OVER €200.
      </motion.h3>
      {width > 1278 ? (
        <Button
          text={itemAlreadyAdded ? "ITEM ALREADY ADDED" : "ADD ITEM TO CART"}
          product={productDetails}
          onClick={handleAddToCart}
          className={"add-to-cart-button"}
        />
      ) : null}
      <motion.div>
        <motion.h3 className={"paragraph uppercase"}>
          {productDetails.productDescription.toUpperCase()}
        </motion.h3>
        <motion.h3 className={"paragraph uppercase"}>
          COLOR:{" "}
          {productDetails.productData.productColor.map((color, index) => (
            <motion.span key={index}>
              {color.toUpperCase()}
              {index !== productDetails.productData.productColor.length - 1 &&
                ","}
            </motion.span>
          ))}
        </motion.h3>
        <motion.h3 className={"paragraph uppercase"}>
          MATERIAL: {productDetails.productData.productMaterial.toUpperCase()}.{" "}
          {productDetails.productData.productProductionCountry &&
            `
            MADE IN 
            ${productDetails.productData.productProductionCountry.toUpperCase()}.`}
        </motion.h3>
      </motion.div>
    </>
  )
}

export default ProductDetails
